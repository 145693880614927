.mentions {
  font-family: Roboto;
  margin: 2px 0;
  font-size: 14px;
  cursor: pointer;
}

.mentions_mentions__input {
  font-size: 14px;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: Roboto;
  font-size: 14px;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  overflow-wrap: anywhere !important;
}
.mentions--multiLine .mentions__input {
  border: 1px solid transparent;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  overflow-y: auto;
  height: 110px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 14px;
}

.mentions__suggestions__item {
  /* padding: 5px 15px; */
  border-radius: 6px;
  margin: 2px;
  border-bottom: 1px solid transparent;
  /* transition: all 300ms ease-in; */
  color: rgb(76 82 84);
  font-size: 14px;
}
.mentions__suggestions__item:hover {
  /* padding: 5px 15px; */
  border-bottom: 1px solid transparent;
  /* transition: all 300ms ease-in; */
}

.mentions__suggestions__item--focused {
  background-color: #eef7fc;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #CE4961;
  /* text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white; */
  font-weight: 500;
  font-size: 14px;
  pointer-events: none;
  padding: 2px 0px;
  border-radius: 5px;
}
