.custom-radio-button {
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #636566;

  @apply relative bg-white bg-transparent cursor-pointer;
  transition: all 300ms ease-out;
  border-radius: 8px;
  &::after {
    content: '';
    width: 14px;
    height: 10px;
    @apply absolute opacity-0 invisible;
    top: 3px;
    right: 1px;
  }
  &:checked {
    border: 4px solid #CE4961;
    &::after {
      @apply opacity-100 visible;
    }
    .custom-radio-button__label {
      @apply text-grey-900;
    }
  }
  &--sm {
    width: 16px;
    height: 16px;
    &::after {
      top: 2px;
      right: 0;
    }
  }
  &__label {
    font-size: 13px;
    @apply font-medium text-grey-800;
    margin-left: 5px;
  }
}
