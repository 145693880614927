.authentication-module {
  @apply w-full h-full flex items-center flex-col py-12;
  min-height: 100vh;
  overflow: auto;
  @media screen and (max-width: 1500px) {
    zoom: 0.9;
  }
  .img-vector {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  &__container-sm {
    @apply bg-white;
    box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
    border-radius: 20px;
    width: 502px;
    max-height: 100%;
    padding: 45px 50px;
    @screen xxl {
      padding: 62px 120px;
    }
  }
  &__container {
    width: 640px;
    max-height: 100%;
    @apply bg-white;
    box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
    border-radius: 20px;
    padding: 45px 120px 40px;
    @screen xxl {
      padding: 62px 120px;
    }
    .or-text {
      @apply w-full flex items-center justify-center;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      > span {
        @apply flex items-center justify-center relative text-grey-600 font-medium z-10;
        min-width: 32px;
        height: 18px;
        font-size: 10px;
        top: 9px;
        border: 1px solid #e6e4e1;
        border-radius: 20px;
      }
    }
  }
  &__heading {
    color: #292929;
    @apply font-bold;
  }
  &__one-click {
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 30%;
      top: 14px;
      height: 1px;
      left: 0px;
      background: #e5e5e5;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 30%;
      top: 14px;
      height: 1px;
      right: 0px;
      background: #e5e5e5;
    }
  }
  &.post-login {
    .authentication-module__container {
      padding: 60px 100px 80px;
    }
  }
}
