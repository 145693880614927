.mcq-slide {
  box-sizing: border-box;
  position: relative;

  /* width: 350px;
  max-width: 350px;
  min-width: 350px; */
  // padding: 0.7rem;
  background-color: rgba(255, 255, 255, 0.06);
}

.mcq-slide__wrap {
  box-sizing: border-box;
  overflow-x: hidden;
  // padding: 20px;
  width: 100%;
}

.mcq-slide__wrap__scroll-wrapper {
  box-sizing: border-box;
  // padding: 0px 11px;
  display: flex;
}

.mcq-slide__item {
  box-sizing: border-box;
  border-radius: 10%;

  /* width: 250px;
  height: 250px;
  min-height: 250px;
  min-width: 250px; */
  margin: 18px 8px 8px 8px;
  padding: 20px 20px;
  opacity: 0.5;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-size: contain;
  background-position: center center;
  position: relative;
  background-repeat: no-repeat;
}

.mcq-slide__item__active {
  position: relative;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 10%;
  /* width: 250px;
  height: 250px;
  min-height: 250px;
  min-width: 250px; */
  // margin: 8px;
  padding: 20px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.mcq-slide__item__overlay {
  box-sizing: border-box;
  border-radius: 10%;
  width: 100%;
  height: 100%;
  top: -8px;
  left: -8px;
  margin: 8px;
  padding: 20px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1000;
}

.mcq-slide__item__tag-container {
  z-index: 1001;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.mcq-slide__item__tag {
  z-index: 1001;
  box-sizing: border-box;
  background-color: #11a1ae;
  color: #fff;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
}

.mcq-slide__item__title {
  z-index: 1001;
  box-sizing: border-box;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.mcq-slide__item__sub-title {
  z-index: 1001;
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 10px;
}

.mcq-slide__item__footer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.slide-footer-currency {
  z-index: 1001;
  box-sizing: border-box;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin: auto 0px;
}

.slide-footer-btn {
  z-index: 1001;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #11a1ae;
  color: #fff;
  padding: 5px 22px;
  border-radius: 15px;
  border: none;
}

.slide-footer-btn:hover {
  background: #0e838d;
}

.mcq-slide__controls-item {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
  background-color: #11a1ae;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(145, 137, 137, 0.5);
  transition: all 300ms;
}

.mcq-slide__controls-item > .carosel-action-left-icon {
  box-sizing: border-box;
  position: relative;
  top: 9px;
  left: 9px;
  font-weight: 700;
  font-size: 1rem;
}

.mcq-slide__controls-item > .carosel-action-right-icon {
  box-sizing: border-box;
  position: relative;
  top: 9px;
  left: 10px;
  font-weight: 700;
  font-size: 1rem;
}

.mcq-slide__controls-item__left {
  box-sizing: border-box;
  top: calc(50% - 25px);
  left: 26px;
}

.mcq-slide__controls-item__right {
  box-sizing: border-box;
  top: calc(50% - 25px);
  right: 28px;
}

.mcq-slide__controls-item:hover {
  background: #0e838d;
}
