.card-box-shadow {
  background: #ffffff;
  border: 1px solid rgba(230, 228, 225, 0.8);
  box-shadow: 0px 2px 6px -2px rgba(85, 83, 80, 0.15);
  border-radius: 14px;
}

.table-reports {
  @apply w-full mt-4;
  thead {
    td {
      @apply text-grey-800 font-medium uppercase p-1;
      font-size: 11px;
      letter-spacing: 0.008em;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      border-top: 0.5px solid #e6e4e1;
    }
    td {
      padding: 12px 4px;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}

.btn-reports {
  @apply h-full flex items-center border border-grey-500-secondary px-3 py-1 rounded-lg text-sm mr-2 bg-white cursor-pointer;
  letter-spacing: -0.0024em;
  &__divider {
    width: 1px;
    height: 16px;
    @apply block bg-grey-400;
    margin: 0 6px;
  }
}

.source-report {
  &__card-list {
    @apply py-3;
    &:not(:last-child) {
      @apply border-b border-grey-400;
    }
  }
}
