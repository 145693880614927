.__card_container {
  &_wrap {
    @media screen and (max-width: 500px) {
      min-width: calc(100vw + 25px);
      max-width: calc(100vw + 25px);
      // @media not all and (min-resolution: 0.001dpcm) {
      //   @supports (-webkit-appearance: none) {
      //     min-width: 100vw;
      //   }
      // }
      @supports (-webkit-touch-callout: none) {
        min-width: 100vw;
        max-width: 100vw;
      }
    }
    border-radius: 11px;
    min-width: 502px;
    max-width: 502px;
    padding: 3px;
    background: linear-gradient(89deg, #c94d45 5.49%, #F77B45 96.99%)
  }
  width: 100%;
  position: relative;
  @apply bg-white;
  z-index: 9;
  box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
  border-radius: 20px;
  padding: 45px 51px 45px;
}
