.globel-settings {
  @apply absolute opacity-100 invisible;
  backface-visibility: hidden;
  padding: 4px 10px;
  transition: all 0.3s;
  height: 66%;
  width: 22%;
  bottom: 40px;
  left: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(85, 83, 80, 0.04),
    0px 12px 30px rgba(85, 83, 80, 0.25);
  border-radius: 10px;
  z-index: 999;

  &.show {
    @apply opacity-100 visible;

    .upload-body {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      border: 1px solid red;
      width: 80%;
    }
  }
}

.card-list {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.initial-logo-flash {
  -webkit-animation: spin 2s infinite;
  // width:50px;
  // height:50px;
  // background:#0091EA;
  // margin:20px;
}
// @keyframes flash {
//   0% {
//     opacity: 1;
//   }

//   50% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
}

@keyframes spin {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.back-background {
  height: 29px;
  width: 29px;
  position: relative;
  border-radius: 50%;
  background: #faf9f8;
  cursor: pointer;
  .icon-back {
    position: absolute;
    top: 8px;
    left: 7px;
  }
}
