.tab {
  &__header {
    @apply flex items-center border-b border-grey-400;
    &__list {
      font-size: 13px;
      @apply relative text-grey-700 font-medium pb-3 cursor-pointer;
      &::after {
        content: '';
        @apply w-full absolute left-0 bottom-0 bg-primary opacity-0 invisible;
        height: 2px;
        transition: all 300ms;
      }
      &.active {
        @apply text-primary font-semibold;
        &::after {
          @apply opacity-100 visible;
        }
      }
      &:not(:last-child) {
        margin-right: 36px;
      }
    }
  }
}
