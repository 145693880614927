.action_container {
  @apply flex items-center justify-center box-border;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
    0px 10px 12px -2px rgba(85, 83, 80, 0.12);
  border-radius: 14px;
  padding: 6px;
  width: fit-content;
  left: 0;
  right: 0;
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index: 501;
  .action {
    border-radius: 10px;
    @apply relative flex flex-col items-center justify-between cursor-pointer;
    padding: 13px 0px;
    width: 67px;
    height: 64px;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &:hover,
    &.active {
      background: #eef7fc;
    }
    .draft {
      @apply absolute bg-blue-900 text-white uppercase font-semibold flex items-center justify-center;
      padding: 2px 5px;
      border-radius: 4px;
      top: -7px;
      font-size: 9px;
      height: 14px;
    }
  }
  .label {
    @apply font-semibold text-primary;
    letter-spacing: -0.0026em;
    line-height: 17px;
    font-size: 14px;
  }
  .trash {
    path {
      fill: #ff0000;
    }
  }
}
