.custom_radio_button {
  @apply relative bg-white bg-transparent cursor-pointer;
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  border: 1.5px solid #908f8d;
  transition: all 300ms ease-out;
  border-radius: 50%;
  &::after {
    content: '';
    @apply absolute opacity-0 invisible;
    width: 14px;
    height: 10px;
    top: 0px;
    right: 0px;
  }
  &:checked {
    border: 4px solid #CE4961;
    &::after {
      @apply opacity-100 visible;
    }
  }
}
