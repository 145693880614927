.swal-button {
  background-color: #CE4961;
}

.swal-button--confirm {
  background-color: #CE4961;
}

.swal-button:not([disabled]):hover {
  background-color: #CE4961;
}

.custom-swal {
  .swal-text {
    text-align: center !important;
  }
}
