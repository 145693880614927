.avatar_container {
  @apply relative flex items-center justify-center flex-shrink-0 rounded-full;

  > img {
    @apply w-full h-full rounded-full;
  }

  .initial_div {
    margin-bottom: 0;
  }

  &.border {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.full {
    > img {
      @apply object-cover;
    }
  }

  &.contain {
    > img {
      @apply object-contain;
    }
  }
  &.mini {
    width: 14px;
    height: 14px;
  }
  &.smallest {
    width: 22px;
    height: 22px;
  }
  &.smaller {
    height: 28px;
    width: 28px;
  }

  &.small {
    height: 32px;
    width: 32px;

    .initial {
      font-size: 0.9rem;
    }

    .sup_icon {
      width: 18px;
      height: 18px;
      right: -5px;
      top: -9px;
    }

    .outer_circle {
      position: absolute;
      left: -2px;
      top: -3px;
      background: #CE4961;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      border-width: 3px;
      border-color: #CE4961;
      background: transparent;
    }
  }

  &.medium {
    height: 36px;
    width: 36px;

    .initial {
      font-size: 1rem;
      line-height: 1.55rem;
    }

    .sup_icon {
      width: 20px;
      height: 20px;
      right: -6px;
      top: -9px;
    }
  }

  &.medium_42 {
    height: 42px;
    width: 42px;

    .initial {
      font-size: 15px;
    }
    .sup_icon {
      width: 20px;
      height: 20px;
      right: -6px;
      top: -9px;
    }
  }

  &.large {
    height: 48px;
    width: 48px;

    .initial {
      font-size: 1.1rem;
      line-height: 1.55rem;
    }

    .sup_icon {
      width: 22px;
      height: 22px;
      right: -7px;
      top: -4px;
    }
  }

  &.larger {
    width: 52px;
    height: 52px;

    .initial {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .sup_icon {
      width: 24px;
      height: 24px;
      right: -7px;
      top: -4px;
    }
  }

  &.bigger {
    width: 70px;
    height: 70px;

    .initial {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .sup_icon {
      width: 26px;
      height: 26px;
      right: -7px;
      top: -4px;
    }
  }

  .sup_pic {
    width: 24px !important;
    height: 24px !important;
    top: -3px;
    right: -8px;
  }

  &.bgcolor {
    @apply bg-blue-800;
  }

  .sup_icon {
    @apply flex items-center justify-center absolute bg-secondary rounded-full;
    width: 24px;
    height: 24px;
    right: -7px;
    top: -4px;
    border: 2px solid #ffffff;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
  }
}
