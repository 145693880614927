.accordion {
  &__item {
    @apply py-3 px-4 border border-grey-500 rounded-lg cursor-pointer;
    margin-bottom: 10px;
    &.active {
      @apply border border-primary;
      .accordion__arrow-icon {
        transform: rotate(180deg);
      }
      .accordion__body {
        height: auto;
        max-height: 9999px;
        transition: all 0.5s cubic-bezier(1, 0, 1, 0);
      }
    }
  }
  &__header {
    @apply flex items-center justify-between;
  }
  &__title {
    font-size: 15px;
    @apply font-medium pr-5;
    line-height: 1.2;
  }
  &__content {
    @apply pt-4 pb-1;
    font-size: 15px;
    line-height: 1.4;
  }
  &__body {
    @apply relative overflow-hidden;
    max-height: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    z-index: 1;
  }
  &__arrow-icon {
    width: 12px;
    transition: all 0.3s ease-in;
  }
}
