.password_field {
  @apply relative;
  .eye {
    position: absolute;
    right: 13px;
    top: 15px;
    z-index: 9;
    > img {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
}
