.post-login {
  &__card {
    @apply w-4/12;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    &__container {
      @apply p-3 py-6 bg-white border border-solid border-grey-400 cursor-pointer;
      border-radius: 10px;
      transition: all 300ms;
      &:hover {
        box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
          0px 12px 30px -4px rgba(85, 83, 80, 0.25);
        border: 1px solid transparent;
      }
    }

    &-wrapper {
      @apply flex items-center justify-center flex-wrap mt-10;
      margin-left: -5px;
      margin-right: -5px;
    }
  }
}
