.general-settings {
  &__card-list {
    @apply py-3 cursor-pointer;
    &__avatar-container {
      width: 48px;
      height: 48px;
      @apply flex items-center justify-center flex-shrink-0 rounded-full overflow-hidden bg-grey-300;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
      > img {
        @apply object-contain;
      }
    }
    &__title {
      @apply font-semibold truncate;
    }
    &__desc {
      @apply text-base-1 text-grey-800 truncate;
    }
    &.active,
    &:hover {
      .general-settings__card-list {
        &__avatar-container {
          @apply bg-white;
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .active {
    @apply bg-grey-90;
    .btn--connect {
      @apply opacity-100 visible;
    }
  }
  .toggle-slider {
    &__checkbox {
      width: initial;
      position: absolute;
      opacity: 0;
      &:checked + label .toggle-slider__ball {
        background: #CE4961 !important;
        &:before {
          left: 24px;
        }
        &__small {
          &:before {
            left: 17px;
          }
        }
      }
      &:checked + label .toggle-slider__txt {
        color: rgb(182, 181, 181);
      }
    }
    &__ball {
      display: inline-block;
      vertical-align: middle;
      width: 42px;
      height: 20px;
      background: #c4c2c0 !important;
      border-radius: 22px;
      cursor: pointer;
      padding: 0;
      margin: 0 0 0 1rem;
      position: relative;
      transition: all 0.4s ease;
      &__small {
        width: 31px;
        height: 16px;
        &:before {
          content: '';
          width: 12px !important;
          height: 12px !important;
        }
      }
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: white;
        box-shadow: inset 0 1px 4px 0 rgba(221, 214, 214, 0.5),
          0 0 2px 0 rgba(36, 16, 16, 0.4);
        position: absolute;
        top: 2px;
        left: 3px;
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
  .toggle-slider__checkbox:checked + label .toggle-slider__ball {
    background: #CE4961 !important;
  }
  // .snackbar {
  //   @apply fixed flex items-center text-center px-3 py-2 bg-black;
  //   left: 74%;
  //   top: 10%;
  //   min-width: 140px;
  //   height: 40px;
  //   transform: translate(-50%, -50%);
  //   border-radius: 20px;
  //   box-shadow: 0px 8px 14px rgba(85, 83, 80, 0.25);
  // }

  // .snackbar .symbol {
  //   // flex: 20%;
  //   // margin-right: 8px;
  // }

  // .snackbar .message {
  //   font-size: 15px;
  //   @apply font-medium ml-2;
  //   // flex: 80%;
  //   // text-align: center;
  // }

  #show {
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  #hide {
    visibility: hidden;
  }

  @keyframes fadeIn {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
  &__custom-button {
    background: #CE4961;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.0026em;
    margin-top: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    padding: 8px 35px 8px 35px;
  }
  .connect-channels-list {
    @apply flex items-center justify-between py-3 cursor-pointer;
    &__title {
      @apply text-base-1 font-medium;
    }
    &.active {
      .connect-channels-list {
        &__title {
          @apply font-semibold;
        }
      }
    }
    &::not(:last-child) {
      margin-bottom: 2px;
    }
  }
  //
  &__box-card-bg {
    @apply bg-grey-300;
    border-radius: 14px;
    &__preview-card {
      border: 1.2px solid #CE4961;
      box-shadow: 0px 4px 14px rgba(85, 83, 80, 0.25);
    }
  }
}

.dashboard__main.general-settings {
  .dashboard__main__body {
    height: calc(100% - 79px);
  }
}
