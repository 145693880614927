.avatar_hide {
  transition: 0.3s;
  visibility: hidden;
  width: 0px !important;
}
.avatar_show {
  transition: 0.3s;
  visibility: visible;
}

.focus {
  svg path {
    stroke: #CE4961;
    fill: unset;
  }
}
