.conditional-popup {
  @apply absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center z-10;
  &::after {
    content: '';
    @apply w-full h-full absolute top-0 left-0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }
  &--bg-light {
    &::after {
      background-color: rgba(255, 255, 255, 0.62);
    }
  }
}

.data-overlay {
  @apply bg-grey-400;
  width: 150px;
  height: 14px;
  border-radius: 5px;
  &--md {
    width: 70px;
  }
  &--sm {
    width: 50px;
  }
  &--xs {
    width: 20px;
  }
}
.avatar-overlay {
  width: 20px;
  height: 20px;
  @apply bg-grey-400 rounded-full;
}
