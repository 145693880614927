.dashboard__main {
  &--create-bot {
    display: flex;
    &__section-left {
      width: 25%;
      @apply flex-shrink-0 h-full border-r border-grey-400;
    }
    &__section-right {
      flex: 1;
    }
    .gradient-text {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.0024em;
      background: linear-gradient(90deg, #feac5e 0%, #c779d0 50%, #4bc0c8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      display: inline-block;
    }
    .bot-template-list {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: transparent;
      border-radius: 10px;
      margin: 0 -8px;
      transition: all 300ms;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &:hover {
        background: #eef7fc;
      }
      &__title {
        font-size: 18px;
        font-weight: 600;
      }
      &__desc {
        @apply text-grey-800;
      }
    }
    .bot-assistant-list-hover {
      &:hover {
        @apply bg-grey-90;
      }
    }
    .bot-assistant-list {
      &-wrapper {
        height: calc(100% - 157px);
      }
      @apply flex justify-between items-center border-b border-grey-400;
      padding: 14px 22px;
      transition: all 300ms;

      cursor: pointer;
      &:hover,
      &.active {
        @apply bg-grey-90;
      }
    }
  }
  .ai_assistant_delete_icon {
    width: 30.97px;
    height: 30.97px;
    border-radius: 7.17px;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #fff;
    }
  }
  &--file-upload {
    &__input {
      @apply opacity-0 invisible hidden;
    }

    &-profile {
      &__container {
        @apply relative top-[16px] flex items-center justify-center font-medium cursor-pointer;
        color: #CE4961;
        > img {
          @apply w-full h-full object-cover rounded-full;
        }
      }

      &__icon-wrapper {
        width: 28px;
        height: 28px;
        @apply flex items-center justify-center absolute bottom-0 right-0 bg-white rounded-full;
        box-shadow: 0px 3px 8px -2px rgba(85, 83, 80, 0.14),
          inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .file-upload {
    &__signature {
      @apply flex items-center bg-white border border-secondary rounded-md cursor-pointer;
    }
  }
  .website-tabs {
    height: 65px;
    color: #2b3639;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.886px;
    letter-spacing: 0.12px;
    &.active {
      color: #CE4961;
    }
    .tabs-header:not(:last-child) {
      margin-right: 33px;
    }
  }
}

.create-bot {
  &-section-right {
    display: flex;
    flex-direction: column;
    &__header {
      height: 88px;
      padding: 18px 22px 18px 36px;
      @apply border-b border-grey-400;
      .btn {
        &--help,
        &--publish,
        &--save {
          height: 41px;
        }
        &--help {
          background-color: #f1f1f1;
        }
        &--publish {
          &--icon {
            padding: 10px 8px 10px 18px;
          }
        }
        &--save {
          padding: 10px 13px;
          background-color: #52c26d;
        }
      }
    }
    &__body {
      @apply h-full overflow-y-auto;
    }
    .tabs-wrapper {
      @apply sticky top-0 bg-white z-0;
    }
    .tabs-header {
      @apply text-grey-900 text-xl font-semibold pt-3 pb-4;
      &__active {
        @apply text-primary;
      }
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    .tabs-list-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 33px;
      }
      &:first-child {
        margin-left: 38px;
      }
    }
    .col-4 {
      width: 33.33333333%;
    }
    .input-field-label {
      @apply text-grey-900 text-lg font-semibold mb-5;
      letter-spacing: 0.006em;
      &.mb-3 {
        margin-bottom: 0.75rem;
      }
      &.mb-0 {
        margin-bottom: 0;
      }
    }
    textarea.input {
      &::placeholder {
        color: #949494;
        font-size: 18px;
        font-weight: 400;
      }
      &:focus {
        border: 1px solid #CE4961;
        box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
      }
      &.error {
        border: 1px solid #eb5757 !important;
        box-shadow: none !important;
      }
    }
    .input-field {
      @apply text-grey-800 text-lg;
      padding: 12px 16px;
      background-color: #fff;
      // border: 1px solid #dadada;
      border-radius: 8px;
      &--border2 {
        border: 1.5px solid #c3c2c0 !important;
        &:focus {
          border: 1.5px solid #CE4961 !important;
          box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
        }
      }
      &.bordergrey-500 {
        @apply border border-grey-500;
      }
      &::placeholder {
        color: #949494;
        font-size: 18px;
        font-weight: 400;
      }
      &.text-15 {
        font-size: 15px !important;
        &::placeholder {
          color: #8e8e8e !important;
          font-size: 15px !important;
          font-weight: 400;
        }
      }
      &.text-sm {
        font-size: 14px !important;
        &::placeholder {
          color: #8e8e8e !important;
          font-size: 14px !important;
          font-weight: 400;
        }
      }
      &:focus {
        border: 1px solid #CE4961;
        box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
      }
      &.error {
        &:focus {
          border: 1px solid #eb5757 !important;
          box-shadow: none !important;
        }
      }
      &--h42 {
        height: 42px;
      }
      &--h48 {
        height: 48px;
      }
      &--h50 {
        height: 50px;
      }
      &--h55 {
        height: 55px;
      }
    }
    .send_email {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: -0.031px;
      border: 1px solid #2b3639;
      border-radius: 6px;
      padding: 8px 12px;
      height: 25px;
    }
    .bot-chat-message {
      min-width: 500;
      &__list {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        word-wrap: break-word;
        &.hover {
          .delete-icon-wrapper,
          .drag-and-drop-container {
            display: flex;
          }
          .bot-chat-message {
            &__list__container {
              background-color: #eef7fc;
            }
          }
        }
        &.active {
          .delete-icon-wrapper,
          .drag-and-drop-container,
          .save-icon-wrapper,
          .smiley-icon-wrapper,
          .v-separtor-line {
            display: flex;
          }
          .bot-chat-message {
            &__list__container {
              background-color: #eef7fc;
            }
          }
          .bot-chat-message__input {
            display: block;
            &--container {
              display: none;
            }
          }
        }
        &__container {
          display: flex;
          align-items: center;
          padding: 6px 22px 6px 12px;
          margin: 0 0 0 -12px;
          border-radius: 13px;
          transition: all 300ms;
        }
        .action-icons-container {
          display: flex;
          align-items: center;
          margin-left: 18px;
        }
        .smiley-icon-wrapper,
        .delete-icon-wrapper,
        .save-icon-wrapper,
        .drag-and-drop-container {
          display: flex;
          align-items: center;
          justify-content: center;
          // cursor: pointer;
          display: none;
        }
        .smiley-icon-wrapper {
          width: 30px;
          height: 30px;
        }
        .v-separtor-line {
          display: none;
          width: 1px;
          height: 22px;
          margin: 0 14px 0 17px;
          background-color: #97b4bd;
        }
      }
      &__input {
        display: none;
        width: 380px;
        font-weight: 400;
        border-radius: 10px;
        &--container {
          display: block;
        }
      }
    }
    .data-collection-list {
      display: flex;
      align-items: center;
      &:hover {
        .drag-and-drop-container {
          display: flex;
        }
      }
      .input-field {
        width: 540px;
        // height: 45px;
        border: 1.5px solid #c3c2c0;
        // @apply ml-4;
        &:focus {
          border: 1.5px solid #CE4961;
          box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
        }
      }
      .drag-and-drop-container {
        display: none;
        align-items: center;
        justify-content: center;
        margin-left: 18px;
        transition: all 300ms;
        cursor: pointer;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .bots-brain-list {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      background: #fff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
      border-radius: 14px;
      transition: all 300ms;
      cursor: pointer;
      &-wrapper {
        margin-top: 38px;
      }
      &:hover {
        background-color: #eef7fc;
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }
      &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        font-size: 14px;
        color: #1e42ff;
        padding: 4px 11px;
        margin-left: auto;
        background: rgba(30, 66, 255, 0.14);
        border-radius: 100px;
      }
    }
    .progress-bar-gradient {
      position: relative;
      height: 10px;
      background: #e4e4e4;
      border-radius: 14px;
      &::after {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          90deg,
          #feac5e 0%,
          #c779d0 50%,
          #4bc0c8 100%
        );
        border-radius: 14px;
      }
      &-container {
        padding: 10px 22px;
        border: 1px solid #dadada;
        border-radius: 14px;
      }
    }
    .table-basic-info {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          border-bottom: 1px solid #dadada;
          td {
            @apply text-grey-800 font-semibold;
            padding: 10px;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #dadada;
          vertical-align: top;
          td {
            padding: 14px 10px;
            word-break: break-all;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
              text-align: right;
            }
          }
        }
      }
      .link-text {
        @apply text-grey-900 font-medium cursor-pointer;
        transition: all 300ms;
        &:hover {
          color: #5e60ed;
          text-decoration: underline;
        }
      }
    }
    .col-1 {
      width: 8.33333333%;
    }
    .col-2 {
      width: 16.66666667%;
    }
    .col-3 {
      width: 25%;
    }
    .col-4 {
      width: 33.33333333%;
    }
    .col-5 {
      width: 41.66666667%;
    }
    .col-6 {
      width: 50%;
    }
    .col-7 {
      width: 58.33333333%;
    }
    .col-8 {
      width: 66.66666667%;
    }
    .file-upload-container {
      padding: 24px 24px 30px;
      text-align: center;
      background-color: #fcfcfc;
      border: 1px dashed #dadada;
      border-radius: 14px;
      .btn {
        &--blue-outline {
          color: #5e60ed;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.0026em;
          background: #f9f5ff;
          border: 1px solid #5e60ed;
          border-radius: 6px;
        }
        &--h30 {
          height: 30px;
        }
      }
      .or-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        color: #fff;
        font-size: 10px;
        margin: 0 20px;
        background-color: #2b3639;
        border-radius: 100%;
        letter-spacing: -0.0024em;
      }
    }
    .add-product-info {
      padding: 24px 24px 30px;
      text-align: center;
      background-color: #fcfcfc;
      border: 1px dashed #dadada;
      border-radius: 14px;
      .btn {
        &--blue-outline {
          color: #5e60ed;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.0026em;
          background: #f9f5ff;
          border: 1px solid #5e60ed;
          border-radius: 6px;
        }
        &--h30 {
          height: 30px;
        }
      }
      .or-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        color: #fff;
        font-size: 10px;
        margin: 0 20px;
        background-color: #2b3639;
        border-radius: 100%;
        letter-spacing: -0.0024em;
      }
    }
  }
}

.page-back-btn-container {
  position: sticky;
  top: 0;
  height: 65px;
  padding: 16px 22px 16px 36px;
  @apply flex items-center border-b border-grey-400 bg-white z-10;
  .input-field {
    &--h42 {
      height: 42px;
    }
  }
}

.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid rgb(128 128 128 / 50%) !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0px !important;
}
.chat-bot-list {
  padding: 24px 20px 30px;
  background-color: rgba(94, 96, 237, 0.03);
  @apply border-b border-grey-400;
  &-wrapper {
    min-height: calc(100vh - 150px);
  }
  &__container {
    width: 960px;
    margin: auto;
  }
  &__item {
    display: flex;
  }
  &__avatar {
    width: 35px;
    height: 35px;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 120px;
  }
  &__bubble {
    position: relative;
    max-width: 100%;
    min-width: 40px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.0024em;
    padding: 10px 20px;
    margin-left: 5px;
    background: #fff;
    box-shadow: 0px 4px 10px #e5e6f1;
    border-radius: 1px 30px 30px 18px;
    overflow-wrap: break-word;
    &--normal-msg {
      background: #fff;
      border: 1px solid rgba(94, 96, 237, 0.47);
      box-shadow: 0px 4px 15px #e5e6f1;
      border-radius: 30px;
      margin-top: 0 !important;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background-color: #5e60ed08;
        color: #5e60ed;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
    &__corner-decor {
      display: none;
      position: absolute;
      right: calc(100% - 3px);
      top: 0;
    }
    &:first-child {
      .chat-bot-list__bubble__corner-decor {
        display: block;
      }
    }
  }
  &--reverse {
    background-color: #fff;
    .chat-bot-list {
      &__bubble {
        color: #fff;
        font-weight: 400;
        background: #5e60ed;
        border: 1px solid rgba(94, 96, 237, 0.47);
        border-radius: 30px;
        box-shadow: 0px 4px 15px #e5e6f1;
        &__corner-decor {
          display: none !important;
        }
      }
      &__item {
        justify-content: flex-end;
      }
      &__content {
        align-items: flex-end;
        padding-right: 0;
        padding-left: 120px;
      }
    }
  }
}
.chat-msg {
  &-container {
    @apply sticky left-0 right-0 mx-auto z-10;
    bottom: 28px;
    width: 960px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  &__input {
    // @apply w-full border border-box-border bg-white;
    padding: 20px 24px 20px;
    padding-right: 80px;
    border-radius: 10px;
    &:focus {
      @apply border border-primary;
    }
    &:focus + div {
      background-color: #52c26d;
      svg path {
        fill: #fff;
      }
    }
  }
  div > &__send-icon {
    @apply flex items-center justify-center absolute top-0 bottom-0 my-auto bg-white cursor-pointer;
    width: 30px;
    height: 30px;
    right: 15px;
    transition: background-color 300ms;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
.dropdown-menu {
  &--publish {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 10px);
    left: -159px;
    min-width: 404px;
    max-height: 486px;
    background: #fff;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    border-radius: 10px;
    &.open {
      display: flex;
    }
    .publish-version-list {
      padding: 6px 14px;
      margin-left: -14px;
      margin-right: -14px;
      margin-bottom: 5px;
      border-radius: 14px;
      transition: all 300ms;
      @apply cursor-pointer;
      &-wrapper {
        margin-top: 5px;
      }
      &:hover {
        @apply bg-grey-90;
        .date-time-text {
          display: none;
        }
        .restore-text {
          display: block;
        }
      }
      &__container {
        @apply flex items-start;
      }
      &__icon {
        position: relative;
        top: 5px;
        flex-shrink: 0;
      }
      &__content {
        @apply ml-4;
      }
      .date-time-text {
        display: block;
      }
      .restore-text {
        display: none;
      }
    }
  }
}

.error-count-wrapper {
  display: flex;
  align-items: center;
  height: 18px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  margin-left: 15px;
  background: #f55e5e;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.error-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  margin-left: 3px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn--test-it-out {
  font-size: 18px;
  font-weight: 600;
  padding: 6px 13px;
  border: 1.5px solid #feac5e;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 26px;
}
.btn--start-over {
  height: 40px;
  padding: 6px 12px;
  border: 1.5px solid #feac5e;
  border-radius: 10px;
}

.appearance-screen {
  .theme-color-box {
    width: 36px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .avatar-pic-container {
    position: relative;
    width: 45px;
    height: 45px;
    margin-right: 14px;
    box-shadow: inset 0px 0px 0px 0.945254px rgba(0, 0, 0, 0.05);
    &::after {
      content: url(../../../assets/icons/icon-check-white.svg);
      display: none;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(43, 54, 57, 0.4);
      border-radius: 100%;
      z-index: 1;
    }
    &.active::after {
      display: flex;
    }
  }
  .btn-upload-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: rgba(247, 145, 69, 0.25);
    border: 1px solid #d6d6d6;
    border-radius: 100%;
    cursor: pointer;
  }
}

add-item-input-container {
  @apply flex items-center;
  &__input {
    flex: 1;
  }
  &__delete {
    width: 22px;
    height: 22px;
    @apply hidden items-center justify-center flex-shrink-0 ml-1 border border-dashed border-grey-500 transition-all cursor-pointer;
  }
  &:hover {
    .add-item-input-container {
      &__delete {
        @apply flex;
      }
    }
  }
}

.bots-controls {
  &-container {
    width: 620px;
    @apply border border-grey-400 bg-white;
    border-radius: 14px;
    margin-left: 37px;
  }
  &__input-container {
    min-width: 76px;
    max-width: 80px;
    display: flex;
    align-items: center;
    padding: 6px 14px;
    background: #fff;
    border: 1.5px solid #c4c2c0;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 400px;
  }
  &__input {
    max-width: 26px;
    font-size: 15px;
    border: 0;
    &-with-border {
      max-width: 33px;
      font-size: 15px;
      text-align: center;
      padding: 2px 7px;
      background: #fff;
      border: 1.5px solid #c4c2c0;
      border-radius: 6px;
    }
  }
}

.select-default-dropdown {
  @apply flex items-center text-grey-900;
  font-size: 15px;
  padding: 6px 25px 6px 14px;
  background: #fff url(../../../assets/icons/icon-chevron-down.svg) right 10px
    top 49% no-repeat;
  border: 1.5px solid #c4c2c0;
  border-radius: 8px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
