$filter-background: #ff0000;

.calender-select {
  /*date picker css*/
  .calander-container {
    width: 310px;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    margin: 10px auto;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .center .calander-container,
  .center .date-range-container {
    margin: 10px 40px;
  }
  .calander-container .re-try-overlay {
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .header {
    position: relative;
    height: 75px;
    //height: 26px;
    background-color: #ffffff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      padding: 10px 0;
      justify-content: space-around;
      select {
        background: rgba(255, 255, 255, 0.3);
        border: 0;
        font-size: 13px;
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: auto;
      }
      #months {
        margin: 0 5px;
        // margin-left: 20px;
        width: 75px;
        background: none !important;
        font-size: 14px;
        font-weight: 600;
        color: #363636;
        text-align: center;
      }
      #years {
        margin: 0 5px;
        // margin-right: 20px;
        background: none !important;
        font-size: 14px;
        font-weight: 600;
        color: #363636;
      }
    }
    .bottom {
      padding: 0 20px;
      display: flex;
      font-size: 12px;
      margin-top: 15px;
      width: inherit;
      .day-label {
        @apply text-grey-700;
        flex-grow: 1;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
      }
    }
    button {
      @apply flex items-center justify-center;
      border: 0;
      color: white;
      font-weight: 500;
      padding: 0;
      // border-radius: 50%;
      // background-color: #ecf0f1;
      height: 28px;
      width: 30px;
      background-color: rgba(247, 145, 69, 0.25);
      border-radius: 6px;
      svg {
        height: 8px;
      }
    }
    // .nav-left,
    // .nav-right {
    //   background-color: rgba(247, 145, 69, 0.25);
    //   border-radius: 6px;
    // }
    .nav-right:disabled {
      background: rgba(0, 0, 0, 0.3) !important;
      cursor: not-allowed !important;
    }
    .nav-left:disabled {
      background: rgba(0, 0, 0, 0.3) !important;
      cursor: not-allowed !important;
    }
  }
  .days {
    display: flex;
    padding: 0px 20px;
    flex-wrap: wrap;
    margin-top: 18px;
    .day {
      flex-grow: 1;
      text-align: center;
      height: 30px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      display: flex;
      flex-basis: 13%;
      font-weight: 500;
      color: #000000;
      font-size: 12px;
      cursor: pointer;
      border-radius: 6px;
      &:hover {
        @apply bg-grey-90;
      }
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      &.selected {
        @apply bg-primary;
        // background-color: #11a1ae;
        color: #ffffff;
        font-weight: 600;
      }
      &.today {
        background-color: #dadada;
        font-weight: 600;
        &:hover {
          @apply bg-grey-90;
        }
      }
    }
  }
  .body {
    padding: 20px;
  }
}
