ul,
li {
  list-style: none;
}

.replySent-list-style-none {
  ul {
    list-style: disc !important;

    li {
      list-style: disc !important;
      margin-inline-start: 25px !important;
    }
  }

  ol {
    list-style: auto !important;

    li {
      list-style: auto !important;
      margin-inline-start: 25px !important;
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-15 {
  margin-top: 15px;
}
.text-9 {
  font-size: 9px;
}
.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-13 {
  font-size: 13px;
}

.text-15 {
  font-size: 15px;
}

.text-28 {
  font-size: 28px;
}
.text-30 {
  font-size: 30px;
}
.text-25 {
  font-size: 25px;
}

.text-base-1 {
  font-size: 15px !important;
}

.ml-10-percent {
  margin-left: 10%;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-14 {
  margin-left: 14px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-90 {
  margin-right: 90px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-83 {
  margin-right: 83px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.py-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pr-18 {
  padding-right: 18px;
}

.mt-148 {
  margin-top: 148px;
}

.mt-168 {
  margin-top: 168px;
}

.p-30 {
  padding: 30px;
}

.p-f-10 {
  padding: 10px !important;
}

.pl-15 {
  padding-left: 15px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-115 {
  margin-top: 115px;
}

.pb-9 {
  padding-bottom: 9px;
}

.top-40 {
  top: 40%;
}

.w-f-max {
  width: max-content !important;
}

.br-email-body {
  border-bottom: 1px solid #e5e5e5;
}

.br-newEnquiry {
  border: 1px solid rgba(33, 150, 83, 0.2);
}

.br-reply {
  border: 1px solid rgba(49, 176, 217, 0.2);
}

.br-note {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.br-reminder {
  border: 1px solid rgba(53, 122, 245, 0.2);
}

.br-assign {
  border: 1px solid rgba(245, 94, 94, 0.2);
}

.br-moved {
  border: 1px solid rgba(191, 65, 235, 0.2);
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-left: 15px;
}

.pd-10 {
  padding-bottom: 10px;
}

.h-50 {
  height: 200px;
}

.clip-path {
  width: 73%;
  height: 18px;
  position: absolute;
  top: -2px;
  right: -1px;
  background: white;
  clip-path: polygon(0 0, 100% 0, 100% 96%, 76% 97%, 7.4% 100%, 4% 51%);

  // clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
  // clip-path: polygon(110px 0%, 125px 22px, 100% 22px, 100% 100%, 0 100%, 0% 0%);
  &--border {
    @apply hidden;
    background: #039855;
  }
}

.txt-blue {
  color: #2f80ed;
}

.txt-grey {
  color: #707070;
}

.card {
  &-container {
    border: 1px solid rgba(230, 228, 225, 0.6);
    box-shadow: 0px 4px 22px rgba(85, 83, 80, 0.1);
    border-radius: 14px;
    background: #ffffff;
  }
}

.box-shadow {
  box-shadow: 0px 6px 18px -2px rgba(85, 83, 80, 0.2);
}

.width-fill-available {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.error-msg {
  @apply text-red-900;
  letter-spacing: -0.0024em;

  &--sm {
    @apply text-sm;
  }

  &--xs {
    @apply text-xs;
  }
}

.z-502 {
  z-index: 502 !important;
}

.restored {
  background: rgba(245, 94, 94, 0.1);
  border: 1px solid #f55e5e;
}

.google-login-error {
  background: #fef6f5;
  border-radius: 10px;
  @apply p-5;
  border-top: 4px solid #f04438;
}

.user_item {
  @apply flex justify-between py-3 items-center;
  margin-left: -12px;
  padding-left: 12px;
  margin-right: -12px;
  padding-right: 12px;
  border-radius: 12px;
  transition: opacity 300ms ease-in;

  &:hover {
    @apply bg-grey-90;
  }
}
.user_item-disabled {
  @apply flex justify-between py-3 items-center;
  margin-left: -12px;
  padding-left: 12px;
  margin-right: -12px;
  padding-right: 12px;
  border-radius: 12px;
  transition: opacity 300ms ease-in;

  &:hover {
    background-color: #f9f9f9;
  }
  figure {
    background-color: rgb(170, 170, 170) !important;
    border: 0px solid !important;
  }
}
.resize-none {
  resize: none;
}

.bg-blur {
  @apply flex flex-col p-0;
  padding: 0;
  background: rgba(0, 0, 0, 0.3);
}

.teams-avatar-list {
  @apply flex items-center rounded-xl bg-transparent cursor-pointer;
  padding: 10px 15px;
  transition: all 300ms;

  .hover-visible {
    @apply opacity-0 invisible;
    transition: all 300ms;
  }

  &:hover {
    @apply bg-grey-90;

    .hover-visible {
      @apply opacity-100 visible;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.teams-profile-list {
  @apply flex items-center rounded-xl bg-transparent cursor-pointer;
  padding: 10px 0;
  transition: all 300ms;

  &--sm {
    padding: 8px 0;
  }

  .avatar-container {
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  }

  &__title {
    @apply text-sm font-medium ml-3;
  }

  .hover-visible {
    @apply opacity-0 invisible;
    transition: all 300ms;
  }

  &:hover {
    .hover-visible {
      @apply opacity-100 visible;
    }

    .teams-profile-list__title {
      @apply font-semibold;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.overflow-overlay {
  overflow: overlay;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-b-l-10 {
  border-bottom-left-radius: 10px;
}

.border-b-r-10 {
  border-bottom-right-radius: 10px;
}

.border-t-r-10 {
  border-top-right-radius: 10px;
}

.border-t-l-10 {
  border-top-left-radius: 10px;
}

.border-radius-14 {
  border-radius: 14px;
}

.border-radius-20 {
  border-radius: 20px;
}

.min-w-120 {
  min-width: 120px;
}

.min-w-170 {
  min-width: 170px;
}

.min-w-140 {
  min-width: 140px;
}

.min-w-180 {
  min-width: 180px;
}

.card-box {
  @apply border border-grey-400 bg-white;
  border-radius: 14px;

  &--br-primary {
    @apply border-secondary;
  }

  &--br-secondary {
    @apply bg-grey-100;
    border-color: transparent;
  }

  &--br-10 {
    border-radius: 10px;
  }

  &--br-8 {
    border-radius: 8px;
  }

  &.active {
    @apply pb-0;
    // height: 99%;
  }

  .hide-list {
    visibility: hidden;
  }

  .show-list {
    visibility: visible;
  }
}

.card-box-report {
  overflow-y: auto;

  @media screen and (max-width: 1600px) {
    max-height: 50vh;
  }

  @media screen and (min-width: 1601px) {
    max-height: 60vh;
  }
}

.card-box-report-sales {
  overflow-y: auto;
  display: block;

  @media screen and (max-width: 1600px) {
    max-height: 30vh;
  }

  @media screen and (min-width: 1601px) {
    max-height: 53vh;
  }
}

.edit-signle-line {
  @apply flex items-center justify-between cursor-pointer;

  .hover-visible-icon {
    @apply opacity-0 invisible;
    transition: all 300ms;
  }

  &:hover {
    .hover-visible-icon {
      @apply opacity-100 visible;
    }
  }
}

.min-w-100 {
  min-width: 100px;
}

.file-upload {
  &__input {
    @apply opacity-0 invisible hidden;
  }

  &-profile {
    &__container {
      width: 100px;
      height: 100px;
      @apply relative flex items-center justify-center border-2 border-grey-400 rounded-full bg-grey-300 cursor-pointer;

      > img {
        @apply w-full h-full object-cover rounded-full;
      }
    }

    &__icon-wrapper {
      width: 28px;
      height: 28px;
      @apply flex items-center justify-center absolute bottom-0 right-0 bg-white rounded-full;
      box-shadow: 0px 3px 8px -2px rgba(85, 83, 80, 0.14),
        inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    }
  }
}

.file-upload {
  &__signature {
    @apply flex items-center bg-white border border-secondary rounded-md cursor-pointer;
  }
}

.hover-bg-grey {
  @apply cursor-pointer;

  &:hover {
    @apply bg-grey-90 rounded-xl;
    transition: background-color 300ms;
  }

  &--br-8 {
    &:hover {
      @apply rounded-lg;
    }
  }

  &--br-6 {
    &:hover {
      @apply rounded-md;
    }
  }
}

.hideScrollBar {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.letterspacing24em {
  letter-spacing: -0.0024em;
}

.letterspacing08em {
  letter-spacing: 0.008em;
}

.upgrade-plan-tooltip {
  position: relative;
  z-index: 50;
  &__container {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    // bottom: calc(100% - 20px);
    // left: calc(50% - 120px);
    // z-index: 100;
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 50%;
      bottom: -5px;
      transform: rotate(-45deg);
      background: #fff;
      border: 1px solid transparent;
      border-left-color: #dfdfdf;
      border-bottom-color: #dfdfdf;
      z-index: 999;
    }
    .btn--blue {
      @apply text-xs p-2 bg-[#CE4961] rounded-md;
    }
  }
  &__body {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(#e6e4e1, 0.51);
  }
  &__footer {
    padding: 8px 14px;
  }
  &:hover .upgrade-plan-tooltip__container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}
.upgrade-plan-tooltip-right {
  position: relative;
  z-index: 50;
  &__container {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    bottom: calc(100% - 20px);
    left: calc(50% - 120px);
    z-index: 100;
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 50%;
      bottom: -5px;
      transform: rotate(-45deg);
      background: #fff;
      border: 1px solid transparent;
      border-left-color: #dfdfdf;
      border-bottom-color: #dfdfdf;
      z-index: 999;
    }
    .btn--blue {
      @apply text-xs p-2 bg-[#CE4961] rounded-md;
    }
  }
  &__body {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(#e6e4e1, 0.51);
  }
  &__footer {
    padding: 8px 14px;
  }
  &:hover .upgrade-plan-tooltip-right__container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}
.upgrade-plan-tooltip-top {
  position: relative;
  z-index: 50;
  &__container {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    bottom: 34px;
    left: calc(50% - 123px);
    z-index: 100;
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 50%;
      bottom: -5px;
      transform: rotate(-45deg);
      background: #fff;
      border: 1px solid transparent;
      border-left-color: #dfdfdf;
      border-bottom-color: #dfdfdf;
      z-index: 999;
    }
    .btn--blue {
      @apply text-xs p-2 bg-[#CE4961] rounded-md;
    }
  }
  &__body {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(#e6e4e1, 0.51);
  }
  &__footer {
    padding: 8px 14px;
  }
  &:hover .upgrade-plan-tooltip-top__container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}
.upgrade-plan-tooltip-left {
  position: relative;
  z-index: 50;
  &__container {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    bottom: calc(100% - 61px);
    left: calc(50% - 315px);
    z-index: 100;
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 98%;
      bottom: 44px;
      right: 48px;
      transform: rotate(228deg);
      background: #fff;
      border: 1px solid transparent;
      border-left-color: #dfdfdf;
      border-bottom-color: #dfdfdf;
      z-index: 999;
    }
    .btn--blue {
      @apply text-xs p-2 bg-[#CE4961] rounded-md;
    }
  }
  &__body {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(#e6e4e1, 0.51);
  }
  &__footer {
    padding: 8px 14px;
  }
  &:hover .upgrade-plan-tooltip-left__container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}
.line-height-initial {
  line-height: initial;
}
.upgrade-plan-topbar {
  &__container {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    padding-top: 14px;
    height: 45px;
    justify-content: center;
    display: flex;
    background-color: #CE4961;
    color: #ffffff;
  }
}
.subscription-overlay {
  position: relative;
  &::before {
    position: absolute;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    width: 50%;
    height: 100%;
    background: white;
    content: '';
  }
}

.automation-container {
  @apply mt-10 border border-grey-400 rounded-xl p-6;

  @media screen and (max-width: 1500px) {
    width: 60%;
  }

  @media screen and (min-width: 1500px) and (max-width: 1920px) {
    width: 700px;
  }
}
