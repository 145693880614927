.team-chat-feed {
  &__right-side {
    @apply rounded-lg;
    width: calc(100% - 40px);
    margin-bottom: 25px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    &__task-list {
      @apply flex items-center;

      &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid #f4f4f4;
      }

      &:not(:first-child) {
        padding-top: 12px;
      }
    }
  }

  &__mentions {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px 18px;
    background: rgba(240, 165, 0, 0.1);
  }

  &__new-enquiry {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px 18px;
    background: rgba(238, 247, 252, 1);
  }

  &__task {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px 18px;
    background: rgba(33, 150, 83, 0.05);

    &__empty {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 4px 18px;
      background: rgba(238, 247, 252, 1);
    }
    &__overdue {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 4px 18px;
      background: rgba(255, 0, 0, 0.15);
    }
  }

  &__user-invites {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px 18px;
    background: rgba(238, 247, 252, 0.8);
  }
}
