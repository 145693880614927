.or_text {
  @apply w-full flex items-center justify-center;
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  > span {
    @apply relative z-10;
    font-size: 10px;
    color: #908f8d;
    top: 12px;
    border: 1px solid #e6e4e1;
    border-radius: 20px;
  }
}
