.count_indicator_rounded {
  @apply flex items-center justify-center text-xs text-white font-semibold px-2 rounded-xl;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
  background-color: #da1729;
}

.count_indicator {
  @apply font-medium text-grey-600;
}
