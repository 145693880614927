.custom_radio {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  border: 1.5px solid #636566;
  @apply relative bg-white bg-transparent cursor-pointer;
  transition: all 300ms ease-out;

  &:checked {
    background-color: #CE4961;

    &::after {
      content: '';
      width: 10px;
      height: 7px;
      @apply absolute;
      background: url(/assets/icons/icon-checkbox-circle.svg) no-repeat center;
      top: 3px;
      right: 2px;
    }
  }

  &__md {
    width: 16px;
    height: 16px;
  }
}
