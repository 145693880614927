.avatar-stack {
  display: flex;
  align-items: center;
}

.avatar {
  width: 35px; /* Adjust the size as per your requirement */
  height: 35px; /* Adjust the size as per your requirement */
  border-radius: 50%;
  margin-right: -10px; /* Adjust the spacing between avatars */
  border: 2px solid #e6e4e1; /* Optional: Add border to avatars */
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar:last-child {
  margin-right: 0;
}
