.btn {
  @apply flex items-center justify-center font-semibold rounded-lg;
  padding: 12px 18px;
  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }
  &--primary {
    @apply bg-primary text-white;
    position: relative;
    &--gradient {
      background: linear-gradient(90deg, #feac5e 0%, #c779d0 50%, #4bc0c8 100%);
      letter-spacing: -0.0024em;
    }
  }
  &--secondary {
    @apply bg-grey-400 text-grey-900;
  }
  &--google {
    @apply border border-grey-400 rounded-lg bg-white;
    padding: 11px 14px;
  }
  &--text {
    @apply text-primary;
    padding: 0;
    border-radius: 0;
  }
  &--grey {
    @apply text-grey-900 bg-grey-400;
  }
  &--blocked {
    @apply bg-[#EEEEEE] text-blocked-gray;
    position: relative;
    cursor: not-allowed;
    svg {
      fill: #aaaaaa;
      path {
        stroke: #aaaaaa;
      }
    }
  }
  &--white {
    @apply bg-white;
  }
  &--green {
    @apply text-white;
    background-color: #009b38;
  }
  &--enquiry {
    @apply text-grey-900 text-sm;
    padding: 6px 37px 6px 12px;
  }
  &--md {
    padding: 10px 12px;
    font-size: 15px;
  }
  &--wide-md {
    padding: 10px 32px;
    font-size: 15px;
  }
  &--wide-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  &--wide-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  &--wide-md-40 {
    padding: 10px 38px;
    font-size: 15px;
  }
  &--wide-lg {
    width: 280px;
  }
  &--sm {
    padding: 8px 12px;
    // font-size: 15px;
  }
  &--sm-40 {
    padding: 6px 12px;
  }
  &--sm-60 {
    padding: 6px 25px;
  }
  &--xs {
    padding: 5px 12px;
    font-size: 14px;
  }

  &--xs-28 {
    padding: 4px 4px 4px 8px;
    max-height: 28px;
  }
  &--xs-28-p-1 {
    padding: 3px 3px 3px 8px;
    max-height: 28px;
  }

  &--try-again {
    @apply font-semibold;
    padding: 5px 29px;
    font-size: 14px;
    border-radius: 6px;
  }
  &--wide-sm {
    padding: 8px 16px;
  }
  &--wide-sm-40 {
    padding: 10px 32px;
    font-size: 15px;
  }
  &--edit-buttons {
    @apply font-semibold text-sm;
    width: 68px;
    height: 30px;
  }
  &--wide-xs {
    padding: 8px 8px;
  }
  &--xs-26 {
    font-size: 13px;
    padding: 2px 7px;
  }
  &--facebook {
    @apply text-white;
    background-color: #4267b2;
  }
  &--zapier {
    @apply text-white;
    background-color: #ff4a00;
  }
  &--delete {
    @apply bg-red-900 text-13;
  }
  &--instagram {
    @apply text-white;
    background: linear-gradient(239.62deg, #dd2a7b 7.92%, #f58529 95.65%);
  }
  &--gmail-connect {
    @apply text-white;
    background-color: #4d8ffd;
  }
  &--microsoft-connect {
    @apply text-white;
    background-color: #dc3e15;
  }
  &--file-library {
    padding: 10px 35px;
  }
  &--history {
    padding: 5px 6px;
  }
  &--copy {
    @apply absolute;
    font-size: 13px;
    padding: 6px 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    &--sm {
      padding: 5px 15px;
    }
    &--xs {
      padding: 6px 10px;
    }
  }
  &--input-cancel {
    @apply absolute;
    font-size: 13px;
    padding: 6px 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 70px;

    &--sm {
      padding: 6px 8px;
      right: 60px;
    }
  }
  &--basic {
    @apply rounded-md text-xs flex items-center px-2 py-1 font-medium;
    border: 1px solid rgba(53, 122, 245, 0.2);
  }
  &--emoji-filter {
    @apply bg-grey-900 text-white font-normal text-13;
  }
  &--cancel {
    background: #e6e6e6;
  }
  &--default {
    @apply bg-white;
    border: 1px solid #CE4961;
    padding: 5px 13px;
    font-size: 13px;
  }
  &--filter-active {
    @apply bg-grey-90 text-primary;
    padding: 5px 13px;
    font-size: 13px;
  }
  &--outline {
    @apply bg-white border border-secondary text-primary;
  }
  &--stage {
    @apply bg-blue-100 rounded-md text-sm font-medium;
    padding: 6px 12px;
    margin-right: 6px;
  }
  &--stage-red {
    @apply rounded-md text-sm font-medium text-red-900;
    background: rgba(240, 68, 56, 0.1);
    padding: 6px 12px;
  }
  &--error,
  &--red {
    @apply bg-red-900 text-white;
    &:hover {
      @apply bg-red-900 text-white;
    }
  }
  &--upgrade-plan {
    @apply text-white font-semibold;
    font-size: 13px;
    padding: 5px 16px;
    background-color: #4a3aff;
    border-radius: 6px;
  }
  &--blue {
    background-color: #6401ef;
    color: #fff;
  }
}

.btn {
  transition: width 5s;
  .lds-ring {
    position: absolute;
    right: 23px;
    &.inline {
      position: sticky;
      margin-left: 10px;
    }
  }
  .lds-ring div {
    width: 30px;
    height: 30px;
    margin: auto;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    border-color: rgb(27, 137, 141) transparent transparent transparent;
  }
  &--green {
    .lds-ring div {
      border-color: rgb(255, 255, 255) transparent transparent transparent;
    }
  }
}
// .button--loading::after {
//   content: '';
//   position: absolute;
//   width: 16px;
//   height: 16px;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   border: 4px solid transparent;
//   border-top-color: #ffffff;
//   border-radius: 50%;
//   animation: button-loading-spinner 0.7s ease infinite;
// }

// @keyframes button-loading-spinner {
//   from {
//     transform: rotate(0turn);
//   }

//   to {
//     transform: rotate(1turn);
//   }
// }

// .button--loading span {
//   opacity: 1;
//   transition: opacity 0.5s;
// }
// .button--loading:hover span.active {
//   // -webkit-animation: rightThenLeft 0.5s;
//   // animation: rightThenLeft 0.5s;
//   opacity: 0;
// }

// @-webkit-keyframes rightThenLeft {
//   0% {
//     margin-right: 0;
//   }
//   50% {
//     opacity: 0;
//   }
//   75% {
//     visibility: hidden;
//     margin-right: 30%;
//     opacity: 0;
//   }
//   100% {
//     opacity: 0;
//     visibility: hidden;
//   }
// }

// @keyframes rightThenLeft {
//   0% {
//     margin-right: 0;
//   }
//   50% {
//     opacity: 0;
//   }
//   75% {
//     visibility: hidden;
//     margin-right: 30%;
//     opacity: 0;
//   }
//   100% {
//     opacity: 0;
//     visibility: hidden;
//   }
// }

// .button-loading {
//   border-radius: 4px;
//   background-color: #f4511e;
//   border: none;
//   color: #ffffff;
//   text-align: center;
//   font-size: 28px;
//   padding: 20px;
//   width: 200px;
//   transition: all 0.5s;
//   cursor: pointer;
//   margin: 5px;
//   span {
//     cursor: pointer;
//     display: inline-block;
//     position: relative;
//     transition: 0.5s;

//     &:after {
//       content: '\00bb';
//       position: absolute;
//       opacity: 0;
//       top: 0;
//       right: -20px;
//       transition: 0.5s;
//     }
//   }
//   &:hover {
//     span {
//       padding-right: 25px;
//       &:after {
//         opacity: 1;
//         right: 0;
//       }
//     }
//   }
// }
