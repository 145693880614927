.unassigned {
  .back {
    @apply flex items-center pb-2 px-6;
    border-bottom: 1.5px solid #e6e4e1;
    svg path {
      cursor: pointer;
      stroke: #CE4961;
    }
  }
  &.selected {
    @apply pl-5 py-2;
    border-bottom: 1.5px solid #e6e4e1;
    // display: inline;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:not(.no-hover):hover {
        background-color: #ffffff;
      }
    }
  }
}

.notes {
  @apply no-hover px-4 pt-5;
  textarea {
    width: 100%;
    height: 90px;
    border: 1.5px solid #c4c2c0;
    border-radius: 8px;
    resize: none;
    padding: 10px;
    font-size: 14px;
  }
}

.footer {
  @apply flex justify-end mt-3;
  padding: 10px 15px;
}
