.indicatorContainer {
  .updateStyles {
    &__control {
      // border-color: #cccccc;
      height: 48px;
      // &:focus-visible{
      //     border: 1px solid #CE4961;
      //     box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
      // }
      .updateStyles__value-container {
        .updateStyles__placeholder {
          // font-size: 15px;
          color: #9ca4af;
          // @apply text-grey-600/90;
          font-weight: 500;
        }
        .updateStyles__single-value {
          font-weight: 500;
          color: #292929;
        }
      }
      .updateStyles__indicators {
        .updateStyles__clear-indicator {
          display: none;
        }
        .updateStyles__indicator-separator {
          display: none;
        }
        .updateStyles__indicator {
          padding-right: 14px;
          svg {
            path {
              color: rgb(76, 82, 84);
            }
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}
