/* Set content for font-families */

.show-toolbar {
  /* display: inline-block !important; */
}

.hide-toolbar {
  display: none;
}

.mce-content-body {
  font-size: 12px !important;
}
.text-editor {
  height: 100%;
  .editor-container {
  }
  /* height: calc(100% - 165px); */
  .tox-tinymce {
    border: none !important;
  }
  .sms-char-limit {
    margin: 2px 9px;
    font-size: 13px;
    font-weight: 400;
    color: #c4c2c0;
  }
}

.editor-sms-character {
  margin-left: 10px;
  color: #c4c2c0;
  font-size: 13px;
}

.quill {
  /* height: calc(100% - 45px); */
}

.ql-editor {
  /* min-height: 100px; */
}

.toolbar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0rem !important;
  padding-right: 0.5rem !important;
  border-width: 0px !important;
}

.ql-toolbar {
  display: flex;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
  padding-right: 1rem !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
}

.ql-picker.ql-expanded,
.ql-picker-options {
  /* top: unset; */
  bottom: 100%;
  top: unset !important;
  /* bottom: 100% !important; */
}

.sub-toolbar,
.ql-formats {
  margin-right: 5px !important;
}

.sub-toolbar {
  margin-left: 10px;
}

.toolbar-button {
  margin-right: 8px;
}

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}

.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}

.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}

.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}

.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}

.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}

.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}

.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}

.ql-tooltip {
  left: 50px !important;
}

.ql-editing[data-mode='link'] {
  left: 50px !important;
  top: 30px !important;
  transform: translateY(0px) !important;
}

// .tox-tinymce {
//   height: 100% !important;
// }

.tiny-error {
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 1;
  color: red;
  font-size: 14px;
}
.main-toolbar > div {
  margin-right: 10px;
}
.main-toolbar > .emoji-btn {
  margin-right: 16px;
}

.sub-toolbar {
  & > .toolbar-btn {
    margin-right: 14px;

    img {
      height: 15px;
    }
  }
}
