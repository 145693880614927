.header {
  @apply text-center text-xs font-medium text-grey-600;

  &__text {
    padding: 4px 10px;
  }

  &__sm {
    @apply text-center text-xs font-medium text-grey-600;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 50%;
      top: 7px;
      height: 1px;
      left: 0px;
      background: #ececec;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 50%;
      top: 7px;
      height: 1px;
      right: 0px;
      background: #ececec;
    }
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 50%;
    top: 32px;
    height: 1px;
    left: 45px;
    background: #ececec;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 50%;
    top: 32px;
    height: 1px;
    right: 45px;
    background: #ececec;
  }
}
