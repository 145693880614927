.task-container {
  background: rgba(0, 125, 13, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.task-complete-btn {
  @apply flex items-center text-grey-900 px-2;
  background: #ffffff;
  border: 1.08974px solid #e6e4e1;
  border-radius: 6.53846px;
  height: 28px;
  font-size: 13px;
  cursor: pointer;
  width: max-content;
  > img {
    margin-right: 5px;
    margin-top: 1px;
  }
}
