.chats {
  &-preview {
    @apply flex items-end cursor-pointer;
    padding: 10px 0;

    &__avatar-container {
      width: 48px;
      height: 48px;
      @apply flex-shrink-0 rounded-full overflow-hidden;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

      > img {
        @apply w-full h-full rounded-full object-cover;
      }
      &--contain {
        > img {
          @apply object-contain;
          width: initial;
          height: initial;
          border-radius: 0;
        }
      }
    }

    &__title {
      @apply text-grey-800 font-medium truncate;
    }

    &__msg {
      font-size: 15px;
      @apply text-grey-800 font-medium truncate;
    }

    .notification-label {
      @apply flex items-center gap-1;
      padding: 2px 8px;
      border-radius: 20px;

      &__reply-recieved {
        @apply text-xs text-white font-semibold bg-secondary;
      }
    }

    &__add-info {
      @apply flex flex-col flex-shrink-0 gap-2 ml-auto;
    }

    &__time {
      @apply text-grey-700 text-sm text-right whitespace-nowrap;
    }

    &--new {
      .chats {
        &-preview {
          &__title {
            @apply text-grey-900 font-bold;
          }

          &__msg {
            @apply text-grey-900 font-bold;
          }

          &__time {
            @apply text-grey-900;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 6px;
      }
      .notification-label {
        font-size: 11px;
        @apply font-bold text-white bg-green-700 uppercase;
      }

      .notification-status {
        @apply text-xs font-semibold text-white;
        background: #CE4961;
      }
    }

    &--reply-received {
      .chats {
        &-preview {
          &__title {
            @apply text-grey-900 font-semibold;
          }

          &__msg {
            @apply text-grey-800;
          }

          &__time {
            @apply text-grey-900;
          }
        }
      }

      .notification-label {
        @apply text-xs text-white font-semibold bg-secondary;
      }
    }
  }
}
